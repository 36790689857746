import React, { memo } from 'react'
import { RouteComponentProps } from '@reach/router'
import { graphql } from 'gatsby'
import { SEO, Container } from '@components'
import { Link } from '@elements'

interface NotFoundPageProps extends RouteComponentProps {
  data: { site: { siteMetadata: { title: string } } }
  location: any
}

const NotFoundPage: React.FC<NotFoundPageProps> = props => {
  const { data, location } = props
  const siteTitle = data.site.siteMetadata.title

  return (
    <section style={{ marginTop: '80px', fontFamily: 'Rubik' }}>
      <Container>
        <SEO title={'404: Not Found'} />
        <div style={{ marginTop: '80px', fontFamily: 'Rubik', fontSize: '24px' }}>
          <h1>Page Not Found</h1>
          <p>The link you are trying to reach doesn&#39;t exist... the sadness :(</p>
        </div>

        <Link to="/">BACK TO HOME</Link>
      </Container>
    </section>
  )
}

export default memo(NotFoundPage)

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
